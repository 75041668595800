export default function useRedsys() {
  const implementation = {
    doAuthorize: async (
      checkout,
      {
        onApprove: onApproveCallback,
        onChallenge: onChallengeCallback,
        onError: onErrorCallback,
      },
      locale
    ) => {
      console.debug('Paycomet doAuthorize....')
      const reqBody = {
        sessionId: checkout.id,
      }

      let res

      try {
        res = await $fetch(`/api/checkout/${locale}/authorize`, {
          method: 'POST',
          body: reqBody,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {
        onErrorCallback(error)
        return
      }

      if (res.result === 'WEBVIEW') {
        onChallengeCallback(res.url)
      }

      if (res.result === 'COMPLETED') {
        onApproveCallback()
      }

      if (res.result === 'KO') {
        onErrorCallback(res.message)
      }
    },
    doResult: async (
      checkout,
      { onCompleted: onCompletedSuccess, onError: onCompletedError },
      locale
    ) => {
      const intents = ref(1)

      const { data, error, refresh } = await useFetch(
        `/api/public/checkout/payments/redsys/${locale}/result/${checkout.id}`
      )

      while (intents.value < 5) {
        if (data.value && data.value.result === 'RETRY_LATER') {
          await refresh()
          intents.value++
          if (data.value.result !== 'RETRY_LATER') {
            if (data.value.result === 'COMPLETED') {
              onCompletedSuccess()
              return
            }
            onCompletedError(data.value.message)
            return
          }
          await new Promise((resolve) => setTimeout(resolve, 500))
        } else {
          break
        }
      }

      if (data.value && data.value.result === 'COMPLETED') {
        onCompletedSuccess()
        return
      }

      onCompletedError(data.value?.message)
    },
  }

  return {
    implementation,
  }
}
