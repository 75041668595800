export function useMBWay() {
  const implementation = {
    doAuthorize: async (
      checkout,
      {
        onApprove: onApproveCallback,
        onChallenge: onChallengeCallback,
        onError: onErrorCallback,
      },
      locale
    ) => {
      console.debug('MBWay doAuthorize....')
      const reqBody = {
        sessionId: checkout.id,
      }

      let res

      try {
        res = await $fetch(`/api/checkout/${locale}/authorize`, {
          method: 'POST',
          body: reqBody,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {
        onErrorCallback(error)
        return
      }

      if (res.result === 'WEBVIEW') {
        onChallengeCallback(res.url)
      }

      if (res.result === 'KO') {
        onErrorCallback(res.message)
      }

      if (res.result === 'COMPLETED') {
        onApproveCallback()
      }
    },
  }

  return {
    implementation,
  }
}
